import {
  createStyles,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  SwipeableDrawer,
  Toolbar,
  Tooltip,
  useTheme
} from "@material-ui/core";
import { darken, lighten } from "@material-ui/core/styles/colorManipulator";
import SecurityIcon from "@material-ui/icons/Security";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import {
  AccountCircle,
  ChevronLeft,
  ChevronRight,
  Cloud,
  Code,
  Dashboard as DevicesIcon,
  Description,
  Home,
  Memory,
  Search,
  Security,
  ShowChart as DemoIcon,
  SupervisedUserCircle,
  SyncAlt,
  TapAndPlay,
  YouTube
} from "@material-ui/icons";
import classNames from "classnames";
import { useAuth, useWidth } from "hooks";
import BindaptIcon from "products/Bindapt/BindaptIcon";
import DataDuckIcon from "products/Bindapt/DataDuckIcon";
import BinsIcon from "products/Bindapt/BinsIcon";
import FieldsIcon from "products/AgIcons/FieldsIcon";
import TasksIcon from "products/Construction/TasksIcon";
import MiningIcon from "products/ventilation/MiningIcon";
import { useGlobalState } from "providers";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import {
  hasTutorialPlaylist,
  IsAdaptiveAgriculture,
  IsAdCon,
  isBXT,
  IsMiVent,
  IsOmniAir
} from "services/whiteLabel";
import { isDevelopment, isOffline } from "utils/environment";
import FieldListIcon from "products/AgIcons/FieldList";
import NexusSTIcon from "products/Construction/NexusSTIcon";
import OmniAirDeviceIcon from "products/AviationIcons/OmniAirDeviceIcon";
import PlaneIcon from "products/AviationIcons/PlaneIcon";
import AirportMapIcon from "products/AviationIcons/AirportMapIcon";
import JobsiteIcon from "products/Construction/JobSiteIcon";
import MarketplaceIcon from "products/CommonIcons/marketplaceIcon";
import ObjectHeaterIcon from "products/Construction/ObjectHeaterIcon";
import JohnDeereIcon from "products/CommonIcons/johnDeereIcon";
import CNHiIcon from "products/CommonIcons/cnhiIcon";
import CableIcon from "products/Bindapt/CableIcon";
import ContractsIcon from "products/CommonIcons/contractIcon";

const drawerWidth = 230;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    sideMenu: {
      position: "fixed",
      height: "100%",
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: "nowrap",
      background: theme.palette.background.default
    },
    sideMenuOpened: {
      zIndex: theme.zIndex.drawer + 2,
      width: drawerWidth,
      transition: theme.transitions.create(["width"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
      })
    },
    sideMenuOnClosed: {
      transition: theme.transitions.create(["width", "z-index", "opacity"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }),
      overflowX: "hidden",
      width: theme.spacing(7) + 1,
      zIndex: theme.zIndex.drawer,
      opacity: 0,
      [theme.breakpoints.up("md")]: {
        width: theme.spacing(9) + 1,
        opacity: 1
      }
    },
    list: {
      paddingTop: 0
    },
    listItem: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      [theme.breakpoints.up("md")]: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3)
      }
    },
    activeItem:
      theme.palette.type === "light"
        ? {
            background: darken(theme.palette.background.default, 0.25),
            "&:hover": {
              background: darken(theme.palette.background.default, 0.25)
            }
          }
        : {
            background: lighten(theme.palette.background.default, 0.25),
            "&:hover": {
              background: lighten(theme.palette.background.default, 0.25)
            }
          }
  })
);

interface Props {
  open: boolean;
  onOpen: () => void;
  onClose: () => void;
}

export default function SideNavigator(props: Props) {
  const classes = useStyles();
  const theme = useTheme();
  const width = useWidth();
  const location = useLocation();
  const { onOpen, onClose } = props;
  const { isAuthenticated } = useAuth();
  const [{ user }] = useGlobalState();
  const iOS = typeof window !== "undefined" && /iPad|iPhone|iPod/.test(navigator.userAgent);

  const isWelcomePage = (): boolean => {
    return location.pathname.startsWith("/welcome");
  };
  const open = props.open || isWelcomePage();

  const authenticatedSideMenu = () => {
    const isAdaptive = IsAdaptiveAgriculture();
    const isMiVent = IsMiVent();
    const isAdCon = IsAdCon(); //check if logged in from the AdCon site
    const isOmniAir = IsOmniAir();
    return (
      <List className={classes.list} component="nav">
        {user.hasFeature("maps") && isAdCon && (
          <Tooltip title="Site Map" placement="right">
            <ListItem
              id="constructionsitemap"
              button
              component={Link}
              to="/constructionsitemap"
              onClick={onClose}
              classes={{
                button: classNames(
                  location.pathname.startsWith("/constructionsitemap") && classes.activeItem,
                  classes.listItem
                )
              }}>
              <ListItemIcon>
                <FieldsIcon />
              </ListItemIcon>
              {open && <ListItemText primary="Site Map" />}
            </ListItem>
          </Tooltip>
        )}
        {isAdCon && (
          <Tooltip title="Jobsites" placement="right">
            <ListItem
              id="jobsites"
              button
              component={Link}
              to="/jobsites"
              onClick={onClose}
              classes={{
                button: classNames(
                  location.pathname.startsWith("/jobsites") && classes.activeItem,
                  classes.listItem
                )
              }}>
              <ListItemIcon>
                <JobsiteIcon />
              </ListItemIcon>
              {open && <ListItemText primary="Jobsites" />}
            </ListItem>
          </Tooltip>
        )}
        {isAdCon && (
          <Tooltip title="Heaters" placement="right">
            <ListItem
              id="objectHeaters"
              button
              component={Link}
              to="/objectHeaters"
              onClick={onClose}
              classes={{
                button: classNames(
                  location.pathname.startsWith("/heaters") && classes.activeItem,
                  classes.listItem
                )
              }}>
              <ListItemIcon>
                <ObjectHeaterIcon />
              </ListItemIcon>
              {open && <ListItemText primary="Heaters" />}
            </ListItem>
          </Tooltip>
        )}
        {user.hasFeature("maps") && isOmniAir && (
          <Tooltip title="Airport Map" placement="right">
            <ListItem
              id="aviationmap"
              button
              component={Link}
              to="/aviationmap"
              onClick={onClose}
              classes={{
                button: classNames(
                  location.pathname.startsWith("/aviationmap") && classes.activeItem,
                  classes.listItem
                )
              }}>
              <ListItemIcon>
                <AirportMapIcon />
              </ListItemIcon>
              {open && <ListItemText primary="Airport Map" />}
            </ListItem>
          </Tooltip>
        )}
        {isOmniAir && (
          <Tooltip title="Terminals" placement="right">
            <ListItem
              id="terminals"
              button
              component={Link}
              to="/terminals"
              onClick={onClose}
              classes={{
                button: classNames(
                  location.pathname.startsWith("/terminals") && classes.activeItem,
                  classes.listItem
                )
              }}>
              <ListItemIcon>
                <PlaneIcon />
              </ListItemIcon>
              {open && <ListItemText primary="Terminals" />}
            </ListItem>
          </Tooltip>
        )}

        {user.hasFeature("maps") && isAdaptive && (
          <Tooltip title="Visual Farm" placement="right">
            <ListItem
              id="visualFarm"
              button
              component={Link}
              to="/fields"
              onClick={onClose}
              classes={{
                button: classNames(
                  location.pathname.startsWith("/fields") && classes.activeItem,
                  classes.listItem
                )
              }}>
              <ListItemIcon>
                <FieldsIcon />
              </ListItemIcon>
              {open && <ListItemText primary="Visual Farm" />}
            </ListItem>
          </Tooltip>
        )}
        <Tooltip title="Contracts" placement="right">
          <ListItem
            button
            component={Link}
            to="/contracts"
            onClick={onClose}
            classes={{
              button: classNames(
                location.pathname.startsWith("/contracts") && classes.activeItem,
                classes.listItem
              )
            }}>
            <ListItemIcon>
              <ContractsIcon />
            </ListItemIcon>
            {open && <ListItemText primary="Contracts" />}
          </ListItem>
        </Tooltip>
        {isAdaptive && (
          <Tooltip title="Bins" placement="right">
            <ListItem
              button
              component={Link}
              to="/bins"
              onClick={onClose}
              classes={{
                button: classNames(
                  (location.pathname === "/" || location.pathname.startsWith("/bins")) &&
                    classes.activeItem,
                  classes.listItem
                )
              }}>
              <ListItemIcon>
                <BinsIcon />
              </ListItemIcon>
              {open && <ListItemText primary="Bins" />}
            </ListItem>
          </Tooltip>
        )}
        {isAdaptive && user.hasAdmin() && (
          <Tooltip title="Bin Cable Estimator" placement="right">
            <ListItem
              button
              component={Link}
              to="/binCableEstimator"
              onClick={onClose}
              classes={{
                button: classNames(
                  location.pathname.startsWith("/binCableEstimator") && classes.activeItem,
                  classes.listItem
                )
              }}>
              <ListItemIcon>
                <CableIcon />
              </ListItemIcon>
              {open && <ListItemText primary="Cable Estimates" />}
            </ListItem>
          </Tooltip>
        )}
        {isAdaptive && (
          <Tooltip title="Transactions" placement="right">
            <ListItem
              button
              component={Link}
              to="/transactions"
              onClick={onClose}
              classes={{
                button: classNames(
                  location.pathname.startsWith("/transactions") && classes.activeItem,
                  classes.listItem
                )
              }}>
              <ListItemIcon>
                <SyncAlt />
              </ListItemIcon>
              {open && <ListItemText primary="Transactions" />}
            </ListItem>
          </Tooltip>
        )}
        {(isMiVent || user.hasFeature("admin")) && (
          <Tooltip title="Ventilation" placement="right">
            <ListItem
              button
              component={Link}
              to="/mines"
              onClick={onClose}
              classes={{
                button: classNames(
                  (location.pathname === "/" || location.pathname.startsWith("/mines")) &&
                    classes.activeItem,
                  classes.listItem
                )
              }}>
              <ListItemIcon>
                <MiningIcon />
              </ListItemIcon>
              {open && <ListItemText primary="Ventilation" />}
            </ListItem>
          </Tooltip>
        )}
        <Tooltip title="Devices" placement="right">
          <ListItem
            id="tour-dashboard"
            button
            component={Link}
            to="/devices"
            onClick={onClose}
            classes={{
              button: classNames(
                (location.pathname.startsWith("/device") ||
                  location.pathname.startsWith(
                    "/group" || location.pathname.startsWith("/demo")
                  )) &&
                  classes.activeItem,
                classes.listItem
              )
            }}>
            <ListItemIcon>
              {isAdaptive ? (
                <BindaptIcon />
              ) : isAdCon ? (
                <NexusSTIcon />
              ) : isOmniAir ? (
                <OmniAirDeviceIcon />
              ) : (
                <DevicesIcon />
              )}
            </ListItemIcon>
            {open && <ListItemText primary="Devices" />}
          </ListItem>
        </Tooltip>
        {/* need to check the browser for the ndef reader as nfc will not work through the web without it */}
        {user.hasFeature("admin") && window.NDEFReader && (
          <Tooltip title="NFC" placement="right">
            <ListItem
              button
              component={Link}
              to="/nfc"
              onClick={onClose}
              classes={{
                button: classNames(
                  location.pathname.startsWith("/nfc") && classes.activeItem,
                  classes.listItem
                )
              }}>
              <ListItemIcon>
                <TapAndPlay />
              </ListItemIcon>
              {open && <ListItemText primary="NFC" />}
            </ListItem>
          </Tooltip>
        )}
        {user.hasFeature("admin") && (
          <Tooltip title="Firmware" placement="right">
            <ListItem
              button
              component={Link}
              to="/firmware"
              onClick={onClose}
              classes={{
                button: classNames(
                  location.pathname.startsWith("/firmware") && classes.activeItem,
                  classes.listItem
                )
              }}>
              <ListItemIcon>
                <Memory />
              </ListItemIcon>
              {open && <ListItemText primary="Firmware" />}
            </ListItem>
          </Tooltip>
        )}
        {user.hasFeature("admin") && (
          <Tooltip title="Objects" placement="right">
            <ListItem
              button
              component={Link}
              to="/objects"
              onClick={onClose}
              classes={{
                button: classNames(
                  location.pathname.startsWith("/objects") && classes.activeItem,
                  classes.listItem
                )
              }}>
              <ListItemIcon>
                <Search />
              </ListItemIcon>
              {open && <ListItemText primary="Objects" />}
            </ListItem>
          </Tooltip>
        )}
        {user.hasFeature("admin") && (
          <Tooltip title="Admin" placement="right">
            <ListItem
              button
              component={Link}
              to="/admin"
              onClick={onClose}
              classes={{
                button: classNames(
                  location.pathname.startsWith("/admin") && classes.activeItem,
                  classes.listItem
                )
              }}>
              <ListItemIcon>
                <SecurityIcon />
              </ListItemIcon>
              {open && <ListItemText primary="Admin" />}
            </ListItem>
          </Tooltip>
        )}
        {user.hasFeature("admin") && (
          <Tooltip title="Logs" placement="right">
            <ListItem
              button
              component={Link}
              to="/logs"
              onClick={onClose}
              classes={{
                button: classNames(
                  location.pathname.startsWith("/logs") && classes.activeItem,
                  classes.listItem
                )
              }}>
              <ListItemIcon>
                <DataDuckIcon />
              </ListItemIcon>
              {open && <ListItemText primary="Logs" />}
            </ListItem>
          </Tooltip>
        )}
        {user.hasFeature("admin") && (
          <Tooltip title="Users" placement="right">
            <ListItem
              button
              component={Link}
              to="/users"
              onClick={onClose}
              classes={{
                button: classNames(
                  location.pathname.startsWith("/users") && classes.activeItem,
                  classes.listItem
                )
              }}>
              <ListItemIcon>
                <AccountCircle />
              </ListItemIcon>
              {open && <ListItemText primary="Users" />}
            </ListItem>
          </Tooltip>
        )}
        {user.hasFeature("teams") && (
          <Tooltip title="Teams" placement="right">
            <ListItem
              button
              id="tour-teams"
              component={Link}
              to="/teams"
              onClick={onClose}
              classes={{
                button: classNames(
                  location.pathname.startsWith("/teams") && classes.activeItem,
                  classes.listItem
                )
              }}>
              <ListItemIcon>
                <SupervisedUserCircle />
              </ListItemIcon>
              {open && <ListItemText primary="Teams" />}
            </ListItem>
          </Tooltip>
        )}
        {isAdaptive && user.hasFeature("marketplace") && (
          <Tooltip title="Marketplace" placement="right">
            <ListItem
              button
              component={Link}
              to="/marketplace"
              onClick={onClose}
              classes={{
                button: classNames(
                  location.pathname.startsWith("/marketplace") && classes.activeItem,
                  classes.listItem
                )
              }}>
              <ListItemIcon>
                <MarketplaceIcon />
              </ListItemIcon>
              {open && <ListItemText primary="Marketplace" />}
            </ListItem>
          </Tooltip>
        )}

        {user.hasFeature("security") && isBXT() && (
          <Tooltip title="Security" placement="right">
            <ListItem
              button
              component={Link}
              to="/security"
              onClick={onClose}
              classes={{
                button: classNames(
                  location.pathname.startsWith("/security") && classes.activeItem,
                  classes.listItem
                )
              }}>
              <ListItemIcon>
                <Security />
              </ListItemIcon>
              {open && <ListItemText primary="Security" />}
            </ListItem>
          </Tooltip>
        )}
        {user.hasFeature("docs") && !isOffline() && (
          <Tooltip title="Docs" placement="right">
            <ListItem
              id="tour-docs"
              button
              component={Link}
              to="/docs"
              onClick={onClose}
              classes={{
                button: classNames(
                  location.pathname.startsWith("/docs") && classes.activeItem,
                  classes.listItem
                )
              }}>
              <ListItemIcon>
                <Description />
              </ListItemIcon>
              {open && <ListItemText primary="Docs" />}
            </ListItem>
          </Tooltip>
        )}
        {user.hasFeature("developer") && (
          <Tooltip title="apidocs" placement="right">
            <ListItem
              id="apidocs"
              button
              component={Link}
              to="/apidocs"
              onClick={onClose}
              classes={{
                button: classNames(
                  location.pathname.startsWith("/docs") && classes.activeItem,
                  classes.listItem
                )
              }}>
              <ListItemIcon>
                <Code />
              </ListItemIcon>
              {open && <ListItemText primary="API Docs" />}
            </ListItem>
          </Tooltip>
        )}
        {user.hasFeature("maps") && isAdaptive && (
          <Tooltip title="My Fields" placement="right">
            <ListItem
              id="fieldList"
              button
              component={Link}
              to="/myFields"
              onClick={onClose}
              classes={{
                button: classNames(
                  location.pathname.startsWith("/myFields") && classes.activeItem,
                  classes.listItem
                )
              }}>
              <ListItemIcon>
                <FieldListIcon />
              </ListItemIcon>
              {open && <ListItemText primary="Fields" />}
            </ListItem>
          </Tooltip>
        )}

        {hasTutorialPlaylist() && !isOffline() && (
          <Tooltip title="Tutorial" placement="right">
            <ListItem
              id="tutorial"
              button
              component={Link}
              to="/tutorial"
              onClick={onClose}
              classes={{
                button: classNames(
                  location.pathname.startsWith("/tutorial") && classes.activeItem,
                  classes.listItem
                )
              }}>
              <ListItemIcon>
                <YouTube />
              </ListItemIcon>
              {open && <ListItemText primary="Tutorial" />}
            </ListItem>
          </Tooltip>
        )}
        {user.hasFeature("tasks") && (
          <Tooltip title="Tasks" placement="right">
            <ListItem
              id="tasks"
              button
              component={Link}
              to="/tasks"
              onClick={onClose}
              classes={{
                button: classNames(
                  location.pathname.startsWith("/tasks") && classes.activeItem,
                  classes.listItem
                )
              }}>
              <ListItemIcon>
                <TasksIcon />
              </ListItemIcon>
              {open && <ListItemText primary="Tasks" />}
            </ListItem>
          </Tooltip>
        )}
        {user.hasFeature("john-deere") && isAdaptive && (
          <Tooltip title="John Deere Import" placement="right">
            <ListItem
              button
              component={Link}
              to="/johndeere"
              onClick={onClose}
              classes={{
                button: classNames(
                  location.pathname.startsWith("/johndeere") && classes.activeItem,
                  classes.listItem
                )
              }}>
              <ListItemIcon>
                <JohnDeereIcon />
              </ListItemIcon>
              {open && <ListItemText primary="John Deere Import" />}
            </ListItem>
          </Tooltip>
        )}
        {user.hasFeature("cnhi") && isAdaptive && (
          <Tooltip title="Case New Holland Import" placement="right">
            <ListItem
              button
              component={Link}
              to="/cnhi"
              onClick={onClose}
              classes={{
                button: classNames(
                  location.pathname.startsWith("/cnhi") && classes.activeItem,
                  classes.listItem
                )
              }}>
              <ListItemIcon>
                <CNHiIcon />
              </ListItemIcon>
              {open && <ListItemText primary="Case New Holland Import" />}
            </ListItem>
          </Tooltip>
        )}
      </List>
    );
  };

  const unauthenticatedSideMenu = () => {
    return (
      <List className={classes.list} component="nav">
        <Tooltip title="Home" placement="right">
          <ListItem
            button
            component={Link}
            to="/"
            onClick={onClose}
            classes={{
              button: classNames(location.pathname === "/" && classes.activeItem, classes.listItem)
            }}>
            <ListItemIcon>
              <Home />
            </ListItemIcon>
            {open && <ListItemText primary="Home" />}
          </ListItem>
        </Tooltip>
        <Tooltip title="Hardware" placement="right">
          <ListItem
            button
            component={Link}
            to="/hardware"
            onClick={onClose}
            classes={{
              button: classNames(
                location.pathname.startsWith("/hardware") && classes.activeItem,
                classes.listItem
              )
            }}>
            <ListItemIcon>
              <Memory />
            </ListItemIcon>
            {open && <ListItemText primary="Hardware" />}
          </ListItem>
        </Tooltip>

        <Tooltip title="Data" placement="right">
          <ListItem
            button
            component={Link}
            to="/data"
            onClick={onClose}
            classes={{
              button: classNames(
                location.pathname.startsWith("/data") && classes.activeItem,
                classes.listItem
              )
            }}>
            <ListItemIcon>
              <Cloud />
            </ListItemIcon>
            {open && <ListItemText primary="Data" />}
          </ListItem>
        </Tooltip>

        <Tooltip title="Demo" placement="right">
          <ListItem
            button
            component={Link}
            to="/demo"
            onClick={onClose}
            classes={{
              button: classNames(
                location.pathname.startsWith("/demo") && classes.activeItem,
                classes.listItem
              )
            }}>
            <ListItemIcon>
              <DemoIcon />
            </ListItemIcon>
            {open && <ListItemText primary="Demo" />}
          </ListItem>
        </Tooltip>
        {isDevelopment() && (
          <Tooltip title="Sandbox" placement="right">
            <ListItem
              id="sandbox"
              button
              component={Link}
              to="/sandbox"
              onClick={onClose}
              classes={{
                button: classNames(
                  location.pathname.startsWith("/sandbox") && classes.activeItem,
                  classes.listItem
                )
              }}>
              <ListItemIcon>
                <Code />
              </ListItemIcon>
              {open && <ListItemText primary="Sandbox" />}
            </ListItem>
          </Tooltip>
        )}
      </List>
    );
  };

  return (
    <SwipeableDrawer
      variant={width === "xs" || width === "sm" ? "temporary" : "permanent"}
      classes={{
        paper: classNames(
          classes.sideMenu,
          open ? classes.sideMenuOpened : classes.sideMenuOnClosed
        )
      }}
      anchor="left"
      open={open}
      onOpen={onOpen}
      onClose={onClose}
      disableBackdropTransition={!iOS}
      disableDiscovery={iOS}>
      <Toolbar disableGutters>
        <Grid container direction="row" justify="flex-end">
          <Grid item>
            <IconButton onClick={onClose} aria-label="onClose side menu">
              {theme.direction === "rtl" ? <ChevronRight /> : <ChevronLeft />}
            </IconButton>
          </Grid>
        </Grid>
      </Toolbar>
      <Divider />
      {isAuthenticated || isOffline() ? authenticatedSideMenu() : unauthenticatedSideMenu()}
    </SwipeableDrawer>
  );
}
